.quill
  .ql-container.ql-snow
    border: none
  .ql-toolbar.ql-snow
    border-top: none
    border-left: none
    border-right: none
    border-bottom: 1px solid #0000001f

  .ql-container.ql-bubble
    .ql-editor
      padding: 0

  .ql-container
    font-family: "Roboto","Helvetica","Arial",sans-serif
    font-size: 1rem
.quill.wz-posts
  .ql-container.ql-snow
    border: none
    .ql-editor
      min-height: 200px
  .ql-container.ql-bubble
    .ql-editor
      // for tooltips
      overflow: visible
